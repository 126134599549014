import React from "react"

import styled from "styled-components"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import {
  Container,
  PageTitle,
  PostText,
  mobileWidth,
  desktopWidth
} from "../utils/common-styles"
import ogImage from "../images/image_softwareentwicklerfinden.png"
import VictorBjorklund from "../images/victor_bjorklund.png"

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
    justify-content: stretch;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 65%;
  @media (max-width: ${desktopWidth}px) {
    margin-bottom: 30px;
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
`


const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 35%;
  align-items: flex-end;
  @media (max-width: ${desktopWidth}px) {
    margin-bottom: 30px;
    display: none
  }

  &:last-child {
    margin-right: 0;
  }
`


const AboutPage = ({ path }) => (
  <LayoutPage
    path={path}
    title="Om softwareentwicklerfinden.de"
  >
    <SEO title="Über uns" description="Über uns" image={ogImage} />
    <Container>
      <Columns>

        <LeftColumn>
          {/* <PageTitle>Some about page details fe fedferwf fefgwegrwgw</PageTitle> */}
          <PostText>
            <strong>Hey!</strong><br /><br />
            <p>
              Ich habe die Website erstellt, weil ich einen Bedarf an Informationen zu der Frage
              gesehen habe, wo man talentierte Entwickler findet. Ich habe bemerkt, dass es nicht
              viele Informationen gibt, die einfach erklären, wie und wo sich gute Entwickler finden
              lassen. Natürlich sind dies keine vollständigen Informationen darüber, wie man
              Entwickler findet, sondern es ist mein Versuch, übersichtliche Informationen auf
              interaktive Weise bereitzustellen. Ich habe sowohl eine Reihe von Artikeln darüber,
              was bei der Suche nach Entwicklern zu bedenken ist (Sie finden sie im Blog-Bereich),
              als auch den Hauptschwerpunkt der Seite: ein interaktives Quiz, in dem Sie eine Reihe
              von Fragen beantworten können.
            </p>

            <p>
              Möchten Sie an der Website mitwirken? Sie erreichen mich über die <a href="/contact">Kontakt-Seite</a>.
            </p>

            <p><strong>/Victor Björklund</strong></p>
          </PostText>
        </LeftColumn>


        <RightColumn><img src={VictorBjorklund} alt="Victor Björklund" /></RightColumn>

      </Columns>
    </Container>
  </LayoutPage>
)

export default AboutPage
